//configuration for mobile app above 1.2 version
import React, { useEffect } from "react"
import {
  useConfigurationStore,
  useMobileDevicesStore,
  useUiStore,
} from "hooks/stores"
import { observer } from "mobx-react"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"

import {
  NumberInput,
  SelectBaseline,
  SelectNoiseInput,
  SelectFeedbackInput,
  SelectECAInput,
  SelectSSAValueInput,
} from "../../input/input"
import { SettingLabel, RowWrapper } from "../hearing_profile_tile_styles"
import EargoGenerationService from "../../../../../../services/eargo_generation_service"

const HearingProfileTileInputsNewConfig = ({
  hearingProfile,
  index,
  onChange,
}) => {
  const {
    baseProgramLeft,
    baseProgramRight,
    environmentalOffset,
    soundTuningLeftVolume,
    soundTuningRightVolume,
    soundTuningLeftTreble,
    soundTuningRightTreble,
    soundTuningLeftBass,
    soundTuningRightBass,
    isRightFeedbackEnabled,
    isLeftFeedbackEnabled,
    isEcaEnabled,
    isSsaEnabled,
    ssaValue,
    noiseReduction,
  } = hearingProfile

  const {
    isHearingAssessmentResInTempConfig,
    checkIfShowNormalOnBaselineSelect,
    checkIfIsPresetOfIndex,
    deviceConfiguration,
  } = useConfigurationStore()

  const { isEcaEnabledDevice, isSsaEnabledDevice } = useMobileDevicesStore()

  const { showConfigurationModal } = useUiStore()

  const ProgramCircle = styled.div`
    height: 24px;
    width: 24px;
    margin-left: -30px;
    padding-top: 8px;
    padding-left: 8px;
    background-color: #f2bf45;
    border-radius: 50%;
    font-size: 12px;
    display: inline-block;
  `

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const baselineObject = (text, audiogramIndex) => {
    const objectKey = `${text}-${index}`
    const duplicatePrograms = { 3: 1, 11: 2, 24: 3, 33: 4 } //see https://eargodev.atlassian.net/browse/TNP-358

    let dupIndex
    let labelElements = [
      <SettingLabel key={`label-${objectKey}`}> {text} </SettingLabel>,
    ]

    if (
      (EargoGenerationService.isEargoSE() ||
        EargoGenerationService.isEargoEight()) &&
      index == 0 &&
      (dupIndex = duplicatePrograms[audiogramIndex])
    ) {
      labelElements.push(
        <ProgramCircle
          key={`circle-${objectKey}`}
          data-tip={`Duplicates Preset ${dupIndex}`}
        >
          P{dupIndex}
        </ProgramCircle>
      )
    }
    return labelElements
  }

  return (
    <>
      <RowWrapper>
        {baselineObject(
          "Baseline R",
          deviceConfiguration.hearingAssessmentRightAudiogramIndex
        )}
        <SelectBaseline
          side={"wide"}
          value={baseProgramRight}
          name={`baseProgramRight-${index}-right`}
          edit={showConfigurationModal}
          checkIfIsPresetOfIndex={checkIfIsPresetOfIndex(index)}
          checkIfShowNormalOnBaselineSelect={checkIfShowNormalOnBaselineSelect(
            index
          )}
          disabled={
            environmentalOffset === "NONE" || isHearingAssessmentResInTempConfig
          }
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        {baselineObject(
          "Baseline L",
          deviceConfiguration.hearingAssessmentLeftAudiogramIndex
        )}
        <SelectBaseline
          side={"wide"}
          value={baseProgramLeft}
          name={`baseProgramLeft-${index}-left`}
          edit={showConfigurationModal}
          checkIfIsPresetOfIndex={checkIfIsPresetOfIndex(index)}
          checkIfShowNormalOnBaselineSelect={checkIfShowNormalOnBaselineSelect(
            index
          )}
          disabled={
            environmentalOffset === "NONE" || isHearingAssessmentResInTempConfig
          }
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Volume</SettingLabel>
        <NumberInput
          side={"right"}
          value={soundTuningRightVolume}
          name={`soundTuningRightVolume-${index}-right`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <NumberInput
          side={"left"}
          value={soundTuningLeftVolume}
          name={`soundTuningLeftVolume-${index}-left`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      {EargoGenerationService.hasTrebleSetting() && (
        <RowWrapper>
          <SettingLabel>Treble</SettingLabel>
          <NumberInput
            side={"right"}
            value={soundTuningRightTreble}
            name={`soundTuningRightTreble-${index}-right`}
            edit={showConfigurationModal}
            onChange={onChange}
          />
          <NumberInput
            side={"left"}
            value={soundTuningLeftTreble}
            name={`soundTuningLeftTreble-${index}-left`}
            edit={showConfigurationModal}
            onChange={onChange}
          />
        </RowWrapper>
      )}
      {EargoGenerationService.hasBassSetting() && (
        <RowWrapper>
          <SettingLabel>Bass</SettingLabel>
          <NumberInput
            side={"right"}
            value={soundTuningRightBass}
            name={`soundTuningRightBass-${index}-right`}
            edit={showConfigurationModal}
            onChange={onChange}
          />
          <NumberInput
            side={"left"}
            value={soundTuningLeftBass}
            name={`soundTuningLeftBass-${index}-left`}
            edit={showConfigurationModal}
            onChange={onChange}
          />
        </RowWrapper>
      )}
      <RowWrapper>
        <SettingLabel>FB Correction</SettingLabel>
        <SelectFeedbackInput
          side={"right"}
          value={isRightFeedbackEnabled}
          name={`isRightFeedbackEnabled-${index}-right`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <SelectFeedbackInput
          side={"left"}
          value={isLeftFeedbackEnabled}
          name={`isLeftFeedbackEnabled-${index}-left`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      {isEcaEnabledDevice && (
        <RowWrapper>
          <SettingLabel>Sound Adjust</SettingLabel>
          <SelectECAInput
            side={"wide"}
            value={isEcaEnabled}
            name={`isEcaEnabled-${index}`}
            edit={showConfigurationModal}
            onChange={onChange}
          />
        </RowWrapper>
      )}
      <RowWrapper>
        <SettingLabel>Noise</SettingLabel>
        <SelectNoiseInput
          side={"wide"}
          value={noiseReduction}
          name={`noiseReduction-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      {isSsaEnabledDevice && (
        <RowWrapper>
          <SettingLabel>Smart Sound Adjust</SettingLabel>
          <SelectSSAValueInput
            side={"wide"}
            value={isSsaEnabled}
            name={`isSsaEnabled-${index}`}
            edit={showConfigurationModal}
            onChange={onChange}
            ssaValue={ssaValue}
            environmentalOffset={environmentalOffset}
            disabled={
              environmentalOffset === "TV" || environmentalOffset === "MUSIC"
            }
          />
        </RowWrapper>
      )}
    </>
  )
}

export default observer(HearingProfileTileInputsNewConfig)
