import {
  NormalIcon,
  MusicIcon,
  RestaurantIcon,
  TheaterIcon,
  TvIcon,
  MeetingIcon,
  CrowdIcon,
  PhoneIcon,
  Preset1Icon,
  Preset2Icon,
  Preset3Icon,
  Preset4Icon,
  MaskIcon,
} from "../images/svg"
import React from "react"
import {
  snakeCase,
  camelCase,
  isArray,
  isEmpty,
  sum,
  flattenDeep,
} from "lodash"
import deepMapKeys from "map-keys-deep-lodash"
import AppConfigService from "../services/app_config_service"
import EargoGenerationService from "services/eargo_generation_service"

import { BISGAARD, SE_AUDIOGRAM } from "./bisgaard_index_audiograms"

const config = AppConfigService.getConfig()

const configurationIconPicker = (environmentalOffset) => {
  switch (environmentalOffset) {
    case "NORMAL":
      return <NormalIcon />
    case "RESTAURANT":
      return <RestaurantIcon />
    case "MUSIC":
      return <MusicIcon />
    case "THEATER":
      return <TheaterIcon />
    case "TV":
      return <TvIcon />
    case "PHONE":
      return <PhoneIcon />
    case "MEETING":
      return <MeetingIcon />
    case "CROWD":
      return <CrowdIcon />
    case "MASK":
      return <MaskIcon />
    case "PROGRAM_A":
      return <Preset1Icon />
    case "PROGRAM_B":
      return <Preset2Icon />
    case "PROGRAM_C":
      return <Preset3Icon />
    case "PROGRAM_D":
      return <Preset4Icon />
    default:
      return <NormalIcon />
  }
}

const mapKeysToSnakeCase = (collection) => {
  let parsedData
  if (collection) {
    parsedData = isArray(collection)
      ? collection.map((collection) =>
          deepMapKeys(collection, (v, k) => snakeCase(k))
        )
      : deepMapKeys(collection, (v, k) => snakeCase(k))
  }
  return parsedData
}

const mapKeysToCamelCase = (collection) => {
  let parsedData
  if (collection) {
    parsedData = isArray(collection)
      ? collection.map((collection) =>
          deepMapKeys(collection, (v, k) => camelCase(k))
        )
      : deepMapKeys(collection, (v, k) => camelCase(k))
  }
  return parsedData
}

function arrayMove(arr, fromIndex, toIndex) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

const calculateAverage = (...args) => {
  if (isEmpty(args)) return 0
  const elements = flattenDeep(args)
  return parseFloat((sum(elements) / elements.length).toFixed(2))
}

const findFavouriteInConfiguration = (configuration) => {
  if (configuration.hearingProfiles.length && configuration.favourites.length) {
    let findSlotOfFavouritesLeft, findSlotOfFavouritesRight

    if (configuration.favouriteLeftIndex)
      findSlotOfFavouritesLeft = configuration.hearingProfiles.findIndex(
        (profile) =>
          profile.baseProgramLeft ===
            configuration.favourites[configuration.favouriteLeftIndex]
              ?.program &&
          profile.baseProgramRight ===
            configuration.favourites[configuration.favouriteLeftIndex]
              ?.program &&
          profile.environmentalOffset === "NONE"
      )
    if (configuration.favouriteRightIndex) {
      findSlotOfFavouritesRight = configuration.hearingProfiles.findIndex(
        (profile) =>
          profile.baseProgramLeft ===
            configuration.favourites[configuration.favouriteRightIndex]
              ?.program &&
          profile.baseProgramRight ===
            configuration.favourites[configuration.favouriteRightIndex]
              ?.program &&
          profile.environmentalOffset === "NONE"
      )
    }

    return {
      foundFavouriteLeft: findSlotOfFavouritesLeft,
      foundFavouriteRight: findSlotOfFavouritesRight,
    }
  } else {
    return {
      foundFavouriteLeft: null,
      foundFavouriteRight: null,
    }
  }
}

//https://stackoverflow.com/questions/6832596/how-to-compare-software-version-number-using-js-only-number/53387532
const compareVersions = (a, b) => {
  let i, diff
  let regExStrip0 = /(\.0+)+$/
  let segmentsA = a.replace(regExStrip0, "").split(".")
  let segmentsB = b.replace(regExStrip0, "").split(".")
  let l = Math.min(segmentsA.length, segmentsB.length)

  for (i = 0; i < l; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10)
    if (diff) {
      return diff
    }
  }
  return segmentsA.length - segmentsB.length
}

const parseObjectForChecks = (object) => {
  return JSON.stringify(object, (k, v) =>
    v && typeof v === "object" ? v : "" + v
  )
}

const DEBUG_ENABLED = config.DEBUG_MODE

const OFFSETS = {
  RESTAURANT: "RESTAURANT",
  MASK: "MASK",
  MEETING: "MEETING",
  TV: "TV",
  PHONE: "PHONE",
  MUSIC: "MUSIC",
}

const PRESETS = {
  PROGRAM_A: "PROGRAM_A",
  PROGRAM_B: "PROGRAM_B",
  PROGRAM_C: "PROGRAM_C",
  PROGRAM_D: "PROGRAM_D",
}

const DEFAULT_NOISE_REDUCTION_VALUES = {
  [EargoGenerationService.GENERATIONS.EARGO_FIVE]: {
    RESTAURANT: 13,
    MASK: 13,
    MEETING: 13,
    TV: 10,
    PHONE: 7,
    MUSIC: 0,
    PROGRAM_A: 10,
    PROGRAM_B: 10,
    PROGRAM_C: 13,
    PROGRAM_D: 13,
  },
  [EargoGenerationService.GENERATIONS.EARGO_SIX]: {
    RESTAURANT: 15,
    MASK: 10,
    MEETING: 10,
    TV: 10,
    PHONE: 10,
    MUSIC: 0,
    PROGRAM_A: 10,
    PROGRAM_B: 10,
    PROGRAM_C: 10,
    PROGRAM_D: 10,
  },
  [EargoGenerationService.GENERATIONS.EARGO_SEVEN]: {
    RESTAURANT: 15,
    MASK: 10,
    MEETING: 10,
    TV: 10,
    PHONE: 10,
    MUSIC: 0,
    PROGRAM_A: 10,
    PROGRAM_B: 10,
    PROGRAM_C: 10,
    PROGRAM_D: 10,
  },
  [EargoGenerationService.GENERATIONS.EARGO_EIGHT]: {
    RESTAURANT: 15,
    MEETING: 10,
    TV: 10,
    PHONE: 10,
    MUSIC: 0,
    PROGRAM_A: 10,
    PROGRAM_B: 10,
    PROGRAM_C: 10,
    PROGRAM_D: 10,
  },
  [EargoGenerationService.GENERATIONS.EARGO_SE]: {
    RESTAURANT: 15,
    MEETING: 10,
    TV: 10,
    PHONE: 10,
    MUSIC: 0,
    PROGRAM_A: 10,
    PROGRAM_B: 10,
    PROGRAM_C: 10,
    PROGRAM_D: 10,
  },
}

export {
  calculateAverage,
  configurationIconPicker,
  mapKeysToCamelCase,
  mapKeysToSnakeCase,
  arrayMove,
  findFavouriteInConfiguration,
  BISGAARD,
  SE_AUDIOGRAM,
  compareVersions,
  parseObjectForChecks,
  DEBUG_ENABLED,
  OFFSETS,
  PRESETS,
  DEFAULT_NOISE_REDUCTION_VALUES,
}
